<template>
	<div class="signup" id="wrap">
		<div class="language_btn">
			<!--언어선택-->
			<div class="language_btn01"> <span><img :src="`/assets/img/${langImg[langIndex]}`" class="language_img">{{ language }}<img src="/assets/img/bottom.png" class="bottom_img"></span>
				<div class="dep_01">
					<ul class="dep_02">
						<li v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
							<a href="javascript:;" @click="SetLocale(i)">
								<img :src="`/assets/img/${langImg[i]}`" class="language_img">{{lang}}</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div name="join_form" class="join_form">
			<div class="index_l_box">
				<!--왼쪽 백그라운드 이미지-->
				<div class="inner2" style="position:relative;">
					<div class="sp10"></div>

					<div class="join_div">
						
						<!-- <div class="join_textsrea">
							<p class="input_title">{{$t("join.1")}}</p>
							<textarea :value='term' readonly></textarea>
						</div>
						<div class="sp10"></div> -->
						<div class="index_input">
							<p class="input_title">{{$t("join.9")}}</p>
							<div class="flex-between-center">
								<input type="text" class="index_input01" name="s_username"  :placeholder="`${$t('join.10')}`" v-model="id"  autocomplete='off' :disabled="dupl_check">
								<button class="flex-shrink-0 chk_btn" @click="DuplCheck()" v-if="dupl_check ==false">{{$t('join.44')}}</button>
								<button class="flex-shrink-0 chk_btn" @click="ResetId()" v-if="dupl_check ==true">{{$t('join.47')}}</button>
							</div>
						</div>
						<div class="country_count">
							<p class="input_title">{{$t("join.4")}}</p>
							<vue-country-code @onSelect="onSelect" :preferredCountries="paramsLang" ></vue-country-code>
						</div>
						<div class="index_input">
							<p class="input_title">{{$t("join.5")}}</p>
							<input type="number" class="index_input01 numberic" v-model="phone"  autocomplete='off' :placeholder="`${$t('join.6')}`" >
						</div>
						<div class="index_input">
							<p class="input_title">{{$t("join.7")}}</p>
							<input type="email" class="index_input01" name="email"  :placeholder="`${$t('join.8')}`" v-model="email"  autocomplete='off'>
						</div>
						<div class="index_input">
							<p class="input_title">{{$t("join.2")}}</p>
							<input type="text" class="index_input01"  :placeholder="`${$t('join.3')}`" name="name" v-model="name" autocomplete='off'>
						</div>
						<div class="index_input">
							<p class="input_title">{{$t("join.11")}}</p>
							<input type="Password" class="index_input01"  :placeholder="`${$t('join.12')}`" name="passwd" v-model="password">
						</div>
						<div class="index_input">
							<p class="input_title">{{$t("join.13")}}</p>
							<input type="Password" class="index_input01"  :placeholder="`${$t('join.14')}`" name="conf_passwd" v-model="conf_password">
						</div>
						<div class="index_input">
							<p class="input_title">{{$t("join.32")}}</p>
							<input type="Password" class="index_input01"  :placeholder="`${$t('join.33')}`" name="passwd" v-model="pin">
						</div>
						<div class="index_input">
							<p class="input_title">{{$t("join.34")}}</p>
							<input type="Password" class="index_input01"  :placeholder="`${$t('join.35')}`" name="conf_passwd" v-model="conf_pin">
						</div>
						<div class="index_input">
							<p class="input_title">{{$t("join.15")}}</p>
							<input type="text" class="index_input01" name="c_username"  :placeholder="`${$t('join.16')}`" v-model="recom_id" @change="CheckRecom()" id='recom_id' :disabled="link == 1"  autocomplete='off'>
						</div>
						<div class="index_input">
							<p class="input_title">{{$t("join.41")}}</p>
							<select class="index_input01" v-model='center'>
								<option value='' style='color:#000;display: none'>{{$t('join.42')}}</option>
								<option :value='item.code' style='color:#000' v-for="(item,index) in center_list" :key="index">{{item.name}}</option>
							</select>
						</div>
						<div class="index_input">
							<p class="input_title">{{$t("join.38")}}</p>
							<fieldset class="border-0 flex-start-center gap-1">
								<div class="w-100 text-center">
									<input type="radio" id="contactChoice1" value="L" v-model="line" :disabled="lien_check" class="btn_radio_input d-none"/>
									<label for="contactChoice1" class="fs-px-14 ps-1">{{$t("join.39")}}</label>
								</div>
								<div class="w-100 text-center">
									<input type="radio" id="contactChoice2" value="R" v-model="line" style="margin-left:20px;" :disabled="lien_check" class="btn_radio_input d-none"/>
									<label for="contactChoice2" class="fs-px-14 ps-1">{{$t("join.40")}}</label>
								</div>
							</fieldset>		
						</div>
						<div class="index_input mb-0 signup_btn">
							<button class="button02 w-100" @click="signup_ok()">{{$t('join.17')}}</button>
						</div>
						<div class="text_btn_box">
							<button class="text_btn" @click="$router.push({path:'/'+$i18n.locale+'/login'})">{{$t('join.18')}}</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>

const CryptoJS = require("crypto-js");

export default {
	data(){
		return{
			line : '',
			name: '',
			country_code: '',
			phone : '',
			email: '',
			id: '',
			password: '',
			conf_password: '',
			pin: '',
			conf_pin: '',
			recom_id: '',
			recom_name: '',
			support_id: '',
			support_name: '',
			lang:this.$i18n.locale,
			term :'',
			link :false,
			center : '',
			center_list : [],
			language : this.$t('language.1'),
			langs: [this.$t("language.1"), this.$t("language.2"), this.$t("language.3"), this.$t("language.4"), this.$t("language.5"), this.$t("language.6")],
			paramsLang: ['ko', 'en', 'jp', 'cn', 'vn', 'th'],
			langImg: ['korea.png', 'usa.png', 'jpn.png', 'cha.png', 'vtn.png', 'th.jpg'],
			langIndex: 0,
			lien_check : false,
			recom_check : false,
			dupl_check : false,
		}
	},
	created(){
		this.GetTerm();
		this.GetCenterList();
		this.SetLanguage();
		const userid = this.$route.params.id
		const line = this.$route.params.line
		if(this.$route.params.id != undefined){
			this.recom_id = userid;
			// this.support_id = userid;
			
			this.link = true;
			this.CheckRecom();
			// this.CheckSupport();
		}
		
		if(this.$route.params.line != undefined){
			this.line = line;
			
			this.lien_check = true;
		}
		
	},
	methods:{
		ResetId(){
			this.dupl_check = false;
			this.id = '';
		},
		DuplCheck(){
			const id = this.id;

			const body = {id};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

			this.$http.post(`/member/sign/CheckDupl`,{req}).then(
				res => {
					if(res.status==200){
						if(res.data.code=='200'){
							this.$alert(this.$t('join.46'))
							this.dupl_check = true;
						}else{
							this.$alert(this.$t('join.45'))
							this.dupl_check = false;
						}
					}else{
						loader.hide();
					}

				}
			).catch(() => {});
		},
		onSelect({name, iso2, dialCode}) {
			this.country_code = iso2
		},
		SetLanguage: function() {
			var lang = this.$route.params.lang;
			if(lang == 'ko') {
				this.language =  this.$t("language.1");
				this.langIndex = 0;
			}else if(lang == 'en') {
				this.language = this.$t("language.2");
				this.langIndex = 1;
			}else if(lang == 'jp') {
				this.language = this.$t("language.3");
				this.langIndex = 2;
			}else if(lang == 'cn') {
				this.language = this.$t("language.4");
				this.langIndex = 3;
			}else if(lang == 'vn') {
				this.language = this.$t("language.5");
				this.langIndex = 4;
			}else if(lang == 'th') {
				this.language = this.$t("language.6");
				this.langIndex = 5;
			}else {
				this.language = this.$t("language.1");
				this.langIndex = 0;
			}
		},
		SetLocale: function(locale){
			this.$i18n.locale = this.paramsLang[locale];
		
			this.$router.push({
				params: {lang: this.paramsLang[locale]}
			})
			this.langs = [this.$t("language.1"), this.$t("language.2"), this.$t("language.3"), this.$t("language.4"), this.$t("language.5"), this.$t("language.6")];
			this.language = this.langs[locale];
			this.langIndex = locale;
			this.$router.go(0);
			
		},

		signup_ok(){
			const name =this.name;
			
			const country_code = this.country_code;
			
			
			const email = this.email;
			const id = this.id;
			const password = this.password;
			const conf_password = this.conf_password;
			
			const pin = this.pin;
			const conf_pin = this.conf_pin;
			
			const recom_id = this.recom_id;
			
			const recom_check = this.recom_check;
			const dupl_check = this.dupl_check;

			const line = this.line
			const phone = this.phone;

			if(name ==''){
				this.$alert(this.$t('join.19'))
				return false;
			}
			if(country_code ==''){
				this.$alert(this.$t('join.20'))
				return false;
			}
			if(phone ==''){
				this.$alert(this.$t('join.21'))
				return false;
			}
			
			if(email =='' ){
				this.$alert(this.$t('join.22'))
				return false;
			}

			if(id =='' ){
				this.$alert(this.$t('join.23'))
				return false;
			}
			if(password =='' ){
				this.$alert(this.$t('join.24'))
				return false;
			}

			if(password.length < 4 ){
				this.$alert(this.$t('join.25'))
				return false;
			}


			if(password != conf_password){
				this.$alert(this.$t('join.26'))
				return false;
			}
			
			// if(recom_id ==''){
			// 	this.$alert(this.$t('join.27'))
			// 	return false;
			// }
			
			if(pin =='' ){
				this.$alert(this.$t('join.36'))
				return false;
			}

			if(pin != conf_pin){
				this.$alert(this.$t('join.37'))
				return false;
			}
			

			// if(recom_check ==false){
			// 	this.$alert(this.$t('join.28'))
			// 	return false;
			// }

			if(line ==''){
				this.$alert(this.$t('join.38'))
				return false;
			}
			
			if(dupl_check ==false){
				this.$alert(this.$t('join.48'))
				return false;
			}

			this.join();

		},
		GetCenterList(){
			const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

			this.$http.post(`/member/sign/GetCenterList`,{req}).then(
				res => {
					if(res.status==200){
						if(res.data.code=='200'){
							const e_body = res.data.body;
							const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
							const d_res = bytes.toString(CryptoJS.enc.Utf8);
							const body = JSON.parse(d_res)
							this.center_list = body.list
						}
					}else{
						loader.hide();
					}

				}
			).catch(() => {});
		},
		join(){
			const name =this.name;
			const country_code = this.country_code;
			const phone = this.phone;
			
			const email = this.email;
			const id = this.id;
			const password = this.password;
			
			
			const recom_id = this.recom_id;

			let loader = this.$loading.show({
				loader: 'dots',color:'#ff0073'
			});

			const lang = this.lang;
			const center =  this.center;
			const line = this.line;
			const pin = this.pin
			const body = {name,country_code,phone,email,id,password,recom_id,lang,line,center,pin};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

			this.$http.post(`/member/sign/up`,{req}).then(
				res => {
					if(res.status==200){
						if(res.data.code=='200'){
							loader.hide();
							this.$alert(this.$t('join.29')).then(
								()=>{
									this.$router.push({path:'/'+this.$i18n.locale+'/login'});		
								}
							)
						}else if(res.data.code=='100'){
							loader.hide();
							this.$alert(this.$t('join.30'))
						}else if(res.data.code=='110'){
							loader.hide();
							this.$alert(this.$t('join.31'))
						}
					}else{
						loader.hide();
					}

				}
			).catch(() => {});
		},
		GetTerm(){
			const lang = this.lang;
			const body = {lang};
			const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

			this.$http.post('/member/common/GetTerm',{req}).then(
				(res) =>  {
					if(res.status == 200){
						

						if(res.data.code =="200"){
							this.loading = false;
							const e_body = res.data.body;
							const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
							const d_res = bytes.toString(CryptoJS.enc.Utf8);
							const body = JSON.parse(d_res)

							this.term = body.info.term;
						}
					}
				}   
			)
		},
		CheckRecom : function(){
			this.recom_check = false;
			const reffer = this.recom_id;
			const body = {reffer};
			const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
			this.$http.post(`/member/sign/CheckRefferCode`,{req}).then(
				res => {
					if(res.status==200){
						if(res.data.code=='200'){
							const e_body = res.data.body;
							const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
							const d_res = bytes.toString(CryptoJS.enc.Utf8);
							const body = JSON.parse(d_res)

							this.recom_name = body.info.name;
							this.recom_check = true;
						}else{
							this.recom_id = '';
							this.recom_name = '';
							this.recom_check = false;
							this.$alert(this.$t('join.30'))
						}
					}

				}
			).catch(() => {});
		},
		
	},
	
}
</script>
<style scoped>
	    .join_textsrea {
	        width: 90%;
	        margin-left: 5%;
	    }
		
	    .index_l_box {
	        min-height: 100vh;
	        width: 100%;
	    }
	
		.join_textsrea textarea{
			width:100%;
			height:80px;
	        border-radius: 5px;
			border-color: #ddd;
			font-size: 12px;
			padding: 10px;
		}
		/* 
	    .account{
	        font-size: 14px;
	    }
		.index_input01 {
	        height: 35px;
		}
		.phone_select {
	        height: 35px;
		}
		.index_input02 {
	        height: 35px;
		}
		.index_input03 {
	        height: 35px;
		}
		.form02_span {
	        height: 35px;
		}
		.button02 {
	        height: 45px;
		}
		.button03 {
	        height: 45px;
	    } */
	    
	    /* custom */
	    /* @media screen and (min-width:1024px){
	        .join_div{
	            max-height: 95vh;
	            overflow-y: scroll;
	        }
	    }
	    @media screen and (max-width:1023px){
	        .join_div{
	            max-height: auto;
	            overflow-y: auto;
	        }
	    } */

		.country_count{
			width: 90%;
			margin: 0 auto;
			margin-bottom: 15px;
			font-size: 12px;
		}
		
</style>

